const Dashboard = () => {
  return (
    <div className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800">
      <div className="pt-20 w-full">
        <div className="container mx-auto">
        <h2 className="text-white text-3xl text-center">Dashbaord</h2>
        
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
